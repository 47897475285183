import React from "react"
import Containers from "../components/container"
import Header from "../components/header"
import Headertop from "../components/headertop"
import Footer from "../components/footer"
import Formfooter from "../components/form-footer"
import { Container, Col, Row } from "react-bootstrap"
import * as contactStyles from "../css/contact.module.css"
import { Link } from "gatsby"
import * as homeStyles from "../css/index.module.css"
import { Icon } from '@iconify/react';
import mobileIcon from '@iconify/icons-bytesize/mobile';
import locationIcon from '@iconify/icons-bytesize/location';
import officeBuilding from '@iconify/icons-heroicons-outline/office-building';
import chevronDoubleRight from '@iconify/icons-mdi-light/chevron-double-right';
import Formcontact from "../components/form-contact"
import Bgcontact from "../components/bgcontact"
import Seo from "../components/seo"

const SeoTitle = "ติดต่อหรือขอใบเสนอราคา"
const SeoDecription = "ผู้ผลิตนำเข้าและจำหน่ายอุปกรณ์ตัดเย็บ เส้นด้ายอุตสาหกรรม ทุกชนิด กรอกแบบ form เพื่อติดต่อเรา หรือขอใบเสนอราคาได้แล้ววันนี้"
const url = "/contact/"

export default function Contact() {
    return (
    <Containers>
      <Seo 
        title={SeoTitle}
        description={SeoDecription}
        pathname={url}
       />
      <Headertop />
      <Header />
      <Bgcontact />
      <div className={contactStyles.herob}>
      <Container fluid="xl">
              <Row>
                  <Col><p><Link to="/">Home</Link>  <Icon icon={chevronDoubleRight} />  Contact US</p></Col>
              </Row>
      </Container>
      </div>
      <div className={contactStyles.herosection}>
          <Container fluid="xl">
              <Row>
                <Col md={4}><h2 className={contactStyles.contacthead}>กรอกแบบ Form เพื่อติดต่อเรา</h2>
                <ul className={homeStyles.contactflex}>
                  <li><Icon icon={mobileIcon} height={28} /></li>
                  <li><p className={homeStyles.plive}><b>081-766-7977</b></p><p className={homeStyles.plivedes}>ติดต่อได้ 24 ชั่วโมง</p></li>
                </ul>
                <ul className={homeStyles.contactflex}>
                  <li><Icon icon={officeBuilding} height={28} /></li>
                  <li><p className={homeStyles.plive}><b>เวลาทำการ</b></p><p className={homeStyles.plivedes}>จันทร์ - เสาร์ 8:30 - 17:30</p></li>
                </ul>
                </Col>
                <Col md={8}><Formcontact /></Col>
              </Row>
          </Container>
      </div>
      <div className={contactStyles.herosection}>
          <Container fluid="xl">
              <Row>
                <Col md={4}><h3 className={contactStyles.contactoff}>ติดต่อสำนักงาน</h3>
                  <p>ผู้ผลิตนำเข้าและจำหน่ายอุปกรณ์ตัดเย็บ เส้นด้ายอุตสาหกรรม ทุกชนิด</p>
                  <ul className={homeStyles.contactflex}>
                    <li><Icon icon={locationIcon} height={28} /></li>
                    <li><p className={homeStyles.plive}><b>ที่อยู่</b></p><p className={homeStyles.plivedes}>33/16-27 หมู่ 3 ถนนเพชรเกษม 110 หนองค้างพลู หนองแขม กทม. 10160</p></li>
                  </ul>
                  <ul className={homeStyles.contactflex}>
                    <li><Icon icon={mobileIcon} height={28} /></li>
                    <li><p className={homeStyles.plive}><b>เบอร์โทรศัพท์</b></p><p className={homeStyles.plivedes}>081-766-7977</p></li>
                  </ul>
                </Col>
                <Col md={8}>
                  <iframe
                    title="Map Songthai"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2304.6820363859333!2d100.34996369155941!3d13.718587345092471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e297e49175b095%3A0x1b0f229540149a25!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4l-C4o-C4h-C5hOC4l-C4ouC5gOC4l-C5h-C4geC4i-C5jOC5hOC4l-C4peC5jCDguIjguLPguIHguLHguJQgU29uZ1RoYWkgVGV4dGlsZSBDby4sIEx0ZC4!5e0!3m2!1sen!2sth!4v1630076965008!5m2!1sen!2sth"
                    width="100%"
                    height="400"
                    frameBorder="0"
                    style={{ border: 0, borderRadius:"10px" }}
                    allowFullScreen=""
                    aria-hidden="false"
                  />
                </Col>
              </Row>
          </Container>
      </div>
      <Formfooter />
      <Footer />
    </Containers>
  );
}